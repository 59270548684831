// ServiceAreas.Styles.js
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        color: "#fff",
        maxWidth: 1200,
        margin: "auto",
        marginTop: 70,
        [theme.breakpoints.down('lg')]: {
            maxWidth: 1050,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 80,
        },
    },
    header: {
        marginBottom: theme.spacing(6),
        textAlign: "center",
    },
    titleBox: {
        width: 985,
        margin: 'auto',
        marginBottom: 100,
        [theme.breakpoints.down('md')]: {
            width: '80%',
            marginLeft: '10%',
            marginBottom: 100,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: '0%',
            marginTop: -100,
            marginBottom: 75,
        },
    },
    title: {
        marginTop: 100,
        marginBottom: 20,
        textAlign: 'center',
        fontSize: '1.8em',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5em'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 150,
            fontSize: '1.2em'
        },
    },
    sectionContainer: {
        marginBottom: theme.spacing(6),
    },
    sectionImage: {
        width: "100%",
        height: "450px",
        objectFit: "cover",
        borderRadius: "5px",
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('lg')]: {
            // border: "5px solid #ffffff",
            height: "250px",
        },
    },
    sectionTitle: {
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        fontSize: "1.6em",
        color: "antiqueWhite",
    },
    sectionText: {
        marginBottom: theme.spacing(2),
        fontSize: "1.1em",
    },
    bulletList: {
        listStyleType: 'disclosure-closed',
        marginLeft: theme.spacing(4),
        marginTop: 15,
        fontSize: '1.1em',
        '& li': {
            marginTop: 6,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
        },
    },
    divider: {
        width: "100%",
        margin: "0px auto 20px",
        backgroundColor: "#89e1f2",
        height: "6px"
    },
    phoneLink: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: '1.5em',
        fontWeight: 600,
        fontFamily: 'serif',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2em',
        },
    },
    subText: {
        fontSize: '1.2em',
        fontWeight: 500,
        marginTop: 40,
    },
    serviceSection: {
        backgroundColor: 'rgba(69, 114, 176, 1)',
        padding: "60px !important",
        borderRadius: 10,
        marginBottom: 100,
        boxShadow: "rgb(255 255 255 / 40%) -6px 6px 3px",
        [theme.breakpoints.down('lg')]: {
            padding: "50px !important",
        },
        [theme.breakpoints.down('md')]: {
            padding: "40px !important",

        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#5f8fd0',
            padding: "50px !important",
        },
        [theme.breakpoints.down('xs')]: {
            boxShadow: "none",
            border: 'none',
            padding: "30px !important",
            marginBottom: 65,
        },
    }
}));

export default useStyles;
