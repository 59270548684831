import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '50px',
        backgroundColor: 'rgba(40, 82, 141, 0.6)',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: 'auto',
        flexShrink: 0,
    },
    footerText: {
        fontFamily: 'monospace',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.8em',
        },
    },
    flag: {
        width: 40,
        margin: '0 10px',
    },
}));

export default useStyles;
